const messages = {
  back: 'cambiar fecha y hora',
  buttonText: 'CONFIRMAR VIDEOLLAMADA',
  error: 'Hubo un error al crear la cita, intente más tarde',
  videocall: {
    title: 'Agenda tu video llamada con un experto de sonrisa Moons',
    description: 'Durante esta videollamada gratuita de 30min, platicarás con un experto de sonrisa Moons a quien podrás preguntarle todo sobre nuestros tratamientos, costo y financiamiento para obtener tus Moons.',
    centerName: 'Videollamada con asesor de sonrisa'
  },
  homekit: {
    title: '{Name}, la sonrisa de tus sueños esta cerca',
  }
}

export default messages
