/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react'
import moment from 'moment'
import styles from './Confirm.module.css'
import messages from './Confirm.messages'
import cosmonaut from '../../Assets/images/svg/cosmonautBlack.svg'
import clock from '../../Assets/images/svg/clockBlack.svg'
import implant from '../../Assets/images/svg/implant.svg'
import LoaderSection from '../LoaderSection/LoaderSection'
import close from '../../Assets/images/svg/close.svg'
import Utils from '../../Utils/utils'

/**
 * Confirm component.
 * @returns {void} .
 */
const Confirm = ({
  branch, dateSelected, userData, showModal, newAppointment, nextStep, timeZone
}) => {
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)

  /**
   * Resume component.
   * @returns {void} .
   */
  const resume = () => {
    const { Center_Name, country } = branch
    const { firstName } = userData

    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = timeZone ? Utils.getDateByCountryTimeZone(dateSelected, country)
    : Utils.getDateByCountryTimeZone(dateSelected, 'México')
    return (
      <div className={styles.CenterContainer}>
        <div
          className={styles.Item}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={cosmonaut}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Name}>{firstName}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          {branch.Center_Icon ? (
            <img
              className={styles.Icon}
              alt="icon"
              src={branch.Center_Icon}
            />
          ) : (
            <img
              className={styles.Icon}
              alt="icon"
              src={implant}
            />
          )}
          <div className={styles.AddressContainer}>
            <p className={styles.Center}>{Center_Name}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={clock}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>{date}</p>
            <p className={styles.Address}>{time}</p>
          </div>
        </div>
      </div>
    )
  }

  /**
   * Reschedule.
   * @param {Function} setLoader .
   * @returns {void} .
   */
  const reschedule = async () => {
    setLoader(true)
    const response = await newAppointment()
    setLoader(false)
    if (!response || response.error) {
      const errorText = response.message || messages.error
      setError(errorText)
    } else {
      nextStep()
    }
  }

  return (
    <div className={styles.ConfirmContainer}>
      <img
        onClick={() => showModal()}
        className={styles.Close}
        alt="close"
        src={close}
      />
      <p className={styles.Title}>{messages.titleHeader}</p>
      <p className={styles.Desc}>{messages.desc}</p>
      {resume()}
      <p className={styles.AreUSure}>{messages.areUSure}</p>
      {loader ? (
        <div className={styles.ContainerLoader}>
          <LoaderSection />
        </div>
      ) : (
        <>
          <div
            role="button"
            tabIndex={0}
            className={styles.Reschedule}
            onClick={() => reschedule()}
          >
            {messages.reschedule}
          </div>
          <p className={styles.Error}>{error}</p>
        </>
      )}
      <div
        role="button"
        tabIndex={0}
        className={styles.TextFooter}
        onClick={() => showModal()}
      >
        {messages.textFooter}
      </div>
    </div>
  )
}

export default Confirm
