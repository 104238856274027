export const setTrackingPatient = async (patient) => {
    if (patient && patient.CustomerId) {
        let properties = {
            "customerId": patient.CustomerId
        };
        if (patient.Email) {
            properties.email = patient.Email
        }
        if (patient.Phone) {
            properties.phone = patient.Phone
        }
        if (patient.Patient_Name) {
            properties.name = patient.Patient_Name
        }
        window.freshpaint.identify(patient.CustomerId, properties)
    }
  } 