import { createRef } from 'react'
import currency from 'currency.js'
import moment from 'moment-timezone'

/**
 * Is param a empty object ?.
 * @param {string} obj .
 * @returns {void} .
 */
const isEmptyObject = (obj) => Object.keys(obj).length === 0 && obj.constructor === Object

/**
 * DynamicRef Function
 * @param {String} total .
 * @return {void}
 */
const dynamicRef = (total) => {
  const refs = []
  for (let initial = 0; initial < total; initial++) {
    refs[initial] = createRef()
  }
  return refs
}

/**
 * Get Style Item By Property
 * @param {String} node .
 * @param {String} property .
 * @return {void}
 */
const getStyleItemByProperty = (node, property) => {
  const nodeStyle = window.getComputedStyle(node)
  const styles = parseInt(nodeStyle.getPropertyValue(property), 10)
  return styles
}

/**
 * Get Date by TimeZone
 * @param {String} date .
 * @param {String} country .
 * @return {void}
 */
const getDateByCountryTimeZone = (date, country) => {
  let time = moment(date).format('HH:mm')
  if (country === 'Colombia') {
    time = moment(date).tz('America/Bogota').format('HH:mm')
  }
  return time
}

/**
 * Get Discount
 * @param {String} countryCode .
 * @param {String} currencyProd .
 * @return {void}
 */
const getDiscount = (countryCode, currencyProd) => {
  let referralAmazon = `${currency(1000, { precision: 0 }).format()} ${currencyProd}`
  let referralDiscount = `${currency(1000, { precision: 0 }).format()} ${currencyProd}`
  switch (countryCode) {
  case 'co':
    referralAmazon = `${currency(169607, { precision: 0 }).format()} ${currencyProd}`
    referralDiscount = `${currency(169607, { precision: 0 }).format()} ${currencyProd}`
    break
  default:
    break
  }
  return { referralAmazon, referralDiscount }
}

/**
 * Choose Country Code
 * @param {String} country .
 * @returns {String} .
 */
const chooseCountryCode = (country) => {
  switch (country) {
  case 'Colombia':
    return 'co'
  default:
    return 'mx'
  }
}

export default {
  chooseCountryCode,
  getDiscount,
  isEmptyObject,
  dynamicRef,
  getStyleItemByProperty,
  getDateByCountryTimeZone
}
