/* eslint-disable complexity */
import React from 'react'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import Shell from '../Shell/Shell'
import styles from './Appointment.module.css'
import ErrorQuery from '../Error404/Error404'
import Middleware from '../../Api/Middleware'
import LoaderFullScreen from '../LoaderFullScreen/LoaderFullScreen'
import Utils from '../../Utils/utils'
import {
  DATE_STEP, ACCEPT_STEP, SUCCESS_STEP
} from '../../Utils/constants'
import ChooseDates from '../ChooseDates/ChooseDates'
import Accept from '../Accept/Accept'
import Success from '../Success/Success'
import apiAcuity from '../../Api/Acuity'
import { setTrackingPatient } from '../../Utils/tracking';

/**
 * Appointment Component
 * @return {void}
 */
class Appointment extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      steps: [
        {
          name: DATE_STEP,
          current: true
        },
        {
          name: ACCEPT_STEP,
          current: false
        },
        {
          name: SUCCESS_STEP,
          current: false
        }
      ],
      error: false,
      patient: {},
      branches: [],
      currentBranch: '',
      dateSelected: '',
      appointment: '',
      appointmentId: '',
      timeZone: true,
      updatedAt: ''
    }
  }

  /**
   * Component Did Mount
   * @return {void}
   */
  componentDidMount = () => {
    const { match } = this.props
    const params = match && match.params ? match.params : null
    const PublicKey = params ? params.publicKey : ''

    Middleware.getTable(process.env.REACT_APP_MIDDLEWARE_ITEM, 'Patient', null, { PublicKey }, this.success, this.error)
  }

  /**
   * Component Did Update
   * @param {Object} nextProps .
   * @param {Object} nextState .
   * @return {void}
   */
  componentDidUpdate(nextProps, nextState) {
    const { patient, branches } = this.state
    if (patient && patient !== nextState.patient) {
      setTrackingPatient(patient)
      const Country = patient.Country_Ops
      const Appointment_Type = process.env.REACT_APP_APPOINTMENT_TYPE
      const order = {
        type: 'asc',
        column: 'Order'
      }
      Middleware.getTable(process.env.REACT_APP_MIDDLEWARE_ITEM, 'SmileCenter', order, { Country, Appointment_Type }, this.success, this.error)
    }
    if (branches && branches !== nextState.branches) {
      this.setCurrentBranch(branches[0])
      patient && this.analyzeStatus(patient)
    }
  }

  /**
   * Success Function
   * @param {object} data .
   * @return {void}
   */
  success = (data) => {
    const patient = data.Patient
    const branches = data.SmileCenter ? [data.SmileCenter] : null

    if (patient && patient.Patient_Name.indexOf(' ') === -1) {
      this.setState({
        error: {
          message: 'Faltan los apellidos del paciente'
        }
      })
    }

    patient && this.setState({ patient })
    patient && this.setState({ updatedAt: patient.updatedAt })
    branches && this.setState({ branches })
  }

  /**
   * Error Function
   * @param {object} error .
   * @return {void}
   */
  error = (error) => {
    this.setState({ error })
  }

  /**
   * Analyze Status
   * @param {object} patient .
   * @return {void}
   */
  analyzeStatus = (patient) => {
    const { branches } = this.state

    // const appointmentTypeID = patient && patient.Appointment_Type_Id
    const appointmentId = patient && patient.Appointment_Id
    // const appointmentStatus = patient && patient.Appointment_Status
    // let appointmentDate = patient && patient.Appointment_Date && patient.Appointment_Date.iso
    // appointmentDate = moment(appointmentDate)
    // const now = moment()

    // const currentBranch = Array.isArray(branches)
    //   && branches.filter(branch => branch.Appointment_Type_Id.toString() === appointmentTypeID)[0]

    // if ((appointmentStatus === 'Scheduled' || appointmentStatus === 'Confirmed')){
    //   currentBranch && this.setState({ currentBranch })
    //   appointmentDate && this.setState({ dateSelected: appointmentDate })
    //   if (appointmentDate > now) {
    //     this.goToSpecificsStep(SUCCESS_STEP)
    //     this.setState({ timeZone: false })
    //   }
    // }

    appointmentId && this.setState({ appointmentId })
  }

  /**
   * Go To Specifics Step
   * @param {String} stepName .
   * @return {void}
   */
  goToSpecificsStep = (stepName) => {
    const { steps } = this.state
    const newSteps = JSON.parse(JSON.stringify(steps))

    const indexCurrentStep = steps.findIndex(step => step.current === true)
    const nextIndex = steps.findIndex(step => step.name === stepName)

    newSteps[indexCurrentStep].current = false
    newSteps[nextIndex].current = true
    this.setState({ steps: newSteps })
  }

  /**
   * Select Time
   * @param {String} time .
   * @return {void}
   */
  selectTime = (time) => {
    this.setState({ dateSelected: time })
    this.nextStep()
  }

  /**
   * Set Appointment
   * @param {Object} appointment .
   * @return {void}
   */
  setAppointment = (appointment) => {
    this.setState({ appointment })
    this.nextStep()
  }

  /**
   * Set Current Branch
   * @param {object} currentBranch .
   * @return {void}
   */
  setCurrentBranch = (currentBranch) => {
    this.setState({ currentBranch })
  }

  /**
   * New Appointment
   * @return {void}
   */
  cancelAppointment = async () => {
    const { appointment, appointmentId } = this.state
    let response = ''
    if (appointment) response = await apiAcuity.cancel(appointment.id)
    else if (appointmentId) response = await apiAcuity.cancel(appointmentId)
    this.setState({ timeZone: true })
    return response
  }

  /**
   * Next Steps
   * @param {object} isBack .
   * @return {void}
   */
  nextStep = (isBack = false) => {
    const { steps } = this.state
    const newSteps = JSON.parse(JSON.stringify(steps))
    let count = 1
    const indexCurrentStep = steps.findIndex(step => step.current === true)

    if (isBack && indexCurrentStep > 0) count = -1
    else if (isBack && indexCurrentStep === 0) count = 0

    if (indexCurrentStep === newSteps.length - 1) {
      newSteps[indexCurrentStep].current = false
      newSteps[0].current = true
    } else {
      newSteps[indexCurrentStep].current = false
      newSteps[indexCurrentStep + count].current = true
    }

    this.setState({ steps: newSteps })
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      error, patient, branches, currentBranch, steps,
      dateSelected, updatedAt, appointment, timeZone
    } = this.state
    window.scrollTo(0, 0)

    const userData = {
      firstName: patient && patient.Patient_Name,
      lastName: '',
      email: patient && patient.Email,
      phone: patient && patient.Phone
    }

    const userToSuccess = {
      firstName: patient && patient.Patient_Name,
      country: patient && patient.Country_Ops,
      referralCode: patient && patient.Referral_Code,
      currencyProd: patient && patient.Currency
    }

    if (error) {
      return <ErrorQuery error={error} />
    }

    if (!patient || Utils.isEmptyObject(patient) || branches.length === 0) {
      return <LoaderFullScreen />
    }

    const name = (patient && patient.Patient_Name) || ''
    const currentStep = steps.filter(step => step.current === true)[0]
    const customerId = patient && patient.CustomerId
    const publicKey = patient && patient.PublicKey

    return (
      <Shell>
        <div className={styles.Container}>
          <div className={styles.Wrapper}>
            {currentStep.name === DATE_STEP && (
              <ChooseDates
                branch={currentBranch || branches[0]}
                nextStep={this.nextStep}
                name={name}
                selectTime={this.selectTime}
                country={patient && patient.Country_Ops}
              />
            )}
          </div>
          {currentStep.name === ACCEPT_STEP && (
            <div className={styles.Wrapper}>
              <Accept
                setAppointment={this.setAppointment}
                branch={currentBranch}
                nextStep={this.nextStep}
                dateSelected={dateSelected}
                updatedAt={updatedAt}
                userData={userData}
                customerId={customerId}
                publicKey={publicKey}
                country={patient && patient.Country_Ops}
                history={this.props.history}
              />
            </div>
          )}
          {currentStep.name === SUCCESS_STEP && (
            <div className={styles.Wrapper}>
              <Success
                branch={currentBranch}
                dateSelected={dateSelected}
                userData={userToSuccess}
                appointment={appointment}
                newAppointment={this.cancelAppointment}
                nextStep={this.nextStep}
                timeZone={timeZone}
                history={this.props.history}
              />
            </div>
          )}
        </div>
      </Shell>
    )
  }
}

export default withRouter(Appointment)
