import React, { Component } from 'react'
import moment from 'moment'
import style from './TimeCounter.module.css'
import messages from './TimeCounter.messages'

/**
 * TimeCounter Component
 * @returns {void} .
 */
class TimeCounter extends Component {
  /**
   * TimeCounter constructor
   * @returns {void} .
   */
  constructor() {
    super()
    this.state = {
      time: {
        minutes: '03',
        seconds: '01',
        expired: false
      },
      timeExpired: false
    }
  }

  /**
   * Component Did Mount
   * @returns {void} .
   */
  componentDidMount() {
    this.countDown()
  }

  /**
   * Component Will Unmount
   * @returns {void} .
   */
  componentWillUnmount() {
    this.interval && clearInterval(this.interval)
  }

  countDown = () => {
    const diff = 0
    const second = 1000
    const { time } = this.state
    const { setExpired } = this.props
    const countDownDate = moment().add(time.minutes, 'minute').add(1, 'seconds')

    // Update the count down every 1 second
    this.interval = setInterval(() => {
      const now = moment()

      const distance = countDownDate - now

      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      let seconds = Math.floor((distance % (1000 * 60)) / 1000)

      if (minutes.toString().length === 1) {
        minutes = `0${minutes}`
      }
      if (seconds.toString().length === 1) {
        seconds = `0${seconds}`
      }

      const currentTime = {
        minutes,
        seconds
      }
      this.setState({ time: currentTime })

      if (distance < diff) {
        clearInterval(this.interval)
        const timeExpired = {
          expired: true,
          minutes: '00',
          seconds: '00'
        }
        this.setState({ time: timeExpired, timeExpired: true })
        setExpired && setExpired()
      }
    }, second)
  }

  /**
   * TimeCounter render
   * @returns {void} .
   */
  render() {
    const { time, timeExpired } = this.state
    const { minutes, seconds } = time
    const timeMinute = `${minutes}:${seconds}`
    const title = timeExpired ? messages.expired : messages.advice
    return (
      <div className={[style.CounterContainer, timeExpired ? style.TimeExpired : ''].join(' ')}>
        <p>{title}</p>
        {!timeExpired && <p className={style.Counter}>{timeMinute}</p>}
      </div>
    )
  }
}

export default TimeCounter
