/* eslint-disable complexity */
import React from 'react'
import moment from 'moment-timezone'
import 'moment/locale/es'
import styles from './Success.module.css'
import messages from './Success.messages'
import cosmo from '../../Assets/images/png/astro.png'
import cosmonaut from '../../Assets/images/svg/cosmonautBlue.svg'
import ok from '../../Assets/images/svg/ok.svg'
import clock from '../../Assets/images/svg/clock.svg'
import implant from '../../Assets/images/svg/implant.svg'
import calendar from '../../Assets/images/svg/calendar.svg'
import Footer from '../Footer/Footer'
import FullModal from '../FullModal/FullModal'
import Confirm from '../Confirm/Confirm'
import Utils from '../../Utils/utils'
import queryString from 'query-string'
import { Button } from '@mymoons/ui-library'

/**
 * Success Component
 * @return {void}
 */
class Success extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    let search = {}
    let userDashboardReschedule
    if(this.props.history && this.props.history.location && this.props.history.location.search){
      search = queryString.parse(this.props.history.location.search)
    }
    this.state = {
      showModal: false,
      backToUserDashboardUrl: search.redirectUrl ? search.redirectUrl : undefined,
      userDashboardReschedule
    }
  }

  resume = () => {
    const { branch, dateSelected, userData, timeZone } = this.props
    const {
      Center_Name
    } = branch
    const { firstName, country } = userData
    const appointmentType = process.env.REACT_APP_APPOINTMENT_TYPE
    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = timeZone ? Utils.getDateByCountryTimeZone(dateSelected, country)
      : Utils.getDateByCountryTimeZone(dateSelected, 'México')
    return (
      <div className={styles.CenterContainer}>
        <div
          className={styles.Item}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={cosmonaut}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Name}>{appointmentType === 'homekit' ? firstName : messages.videocall.centerName}</p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          {appointmentType === 'homekit' && (
            <>
              {branch.Center_Icon ? (
                <img
                  className={styles.Icon}
                  alt="icon"
                  src={branch.Center_Icon}
                />
              ) : (
                <img
                  className={styles.Icon}
                  alt="icon"
                  src={implant}
                />
              )}
              <div className={styles.AddressContainer}>
                <p className={styles.Center}>{Center_Name}</p>
              </div>
            </>
          )}
        </div>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={clock}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>{date}</p>
            <p className={styles.Address}>{time}</p>
          </div>
        </div>
      </div>
    )
  }

  buildCalendarLink = () => {
    const { appointment } = this.props
    const dateTime = appointment.datetime
    const timeStart = moment(dateTime).format('YYYYMMDDTHHmmss')
    const timeEnd = moment(dateTime)
      .add(appointment.duration, 'minutes')
      .format('YYYYMMDDTHHmmss')

    const text = appointment.type
    const details = `Ver/modificar cita:%0A${appointment.confirmationPage}`

    const link = `https://calendar.google.com/calendar/r/eventedit?dates=${timeStart}/${timeEnd}&text=${text}&details=${details}&sf=true`
    return link
  }

  showModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  backToUserDashboard = () => {
    const { backToUserDashboardUrl } = this.state
    window.open(backToUserDashboardUrl, '_self')
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const {
      branch, userData, dateSelected, newAppointment, nextStep, timeZone
    } = this.props
    const { Notes } = branch
    const { country, referralCode, currencyProd } = userData
    const { showModal, backToUserDashboardUrl } = this.state
    const appointmentType = process.env.REACT_APP_APPOINTMENT_TYPE
    const linkCalendar = this.buildCalendarLink()
    const content = (
      <Confirm
        branch={branch}
        dateSelected={dateSelected}
        userData={userData}
        showModal={this.showModal}
        newAppointment={newAppointment}
        nextStep={nextStep}
        timeZone={timeZone}
      />
    )

    return (
      <div className={styles.ContainerSuccess}>
        <FullModal
          open={showModal}
          content={content}
        />
        <img
          className={styles.Cosmonaut}
          src={cosmo}
          alt="cosmo"
        />
        <div className={styles.Thanks}>
          <div className={styles.ThanksContainer}>
            <img
              className={styles.Ok}
              src={ok}
              alt="ok"
            />
            <p className={styles.ThanksText}>{messages.thanks}</p>
          </div>
        </div>
        { backToUserDashboardUrl && (
          <Button
            color="red"
            label={messages.backToUserDashboard}
            onClick={() => this.backToUserDashboard()}
            size="medium"
            variant="filled"
            className={styles.ButtonBlue}
          />
        )}
        <div className={[styles.Section, (Notes && appointmentType === 'homekit') ? '' : styles.Center].join(' ')}>
          <div className={styles.ResumeLink}>
            {this.resume()}
            <div className={styles.ContainerLinkCalendar}>
              <img
                className={styles.ImageCalendar}
                alt="calendar"
                src={calendar}
              />
              <a
                href={linkCalendar}
                className={styles.LinkCalendar}
                rel="noopener noreferrer"
                target="_blank"
              >
                {messages.addCalendar}
              </a>
            </div>
          </div>
          {appointmentType === 'homekit' && Notes && (
            <div className={styles.Recommendation}>
              <div className={styles.ContainerRec}>
                <p className={styles.TitleRecommendation}>{messages.recommendation}</p>
                <p className={styles.ItemRec}>
                  {Notes}
                </p>
              </div>
            </div>
          )}
        </div>
        {appointmentType === 'homekit' && (
          <div
            role="button"
            tabIndex={0}
            className={styles.NewAppointment}
            onClick={() => this.showModal()}
          >
            {messages.newAppointment}
          </div>
        )}
        {appointmentType === 'videocall' && (
          <div className={styles.WhiteSpace}></div>
        )}
        <Footer country={country} />
      </div>
    )
  }
}

export default Success
