const messages = {
  map: 'Ver en maps',
  thanks: 'Gracias por agendar, ya queremos verte!',
  addCalendar: 'Agregar a mi calendario',
  recommendation: 'Recomendaciones',
  seeMaps: 'Ver en maps',
  newAppointment: 'REAGENDAR VIDEOLLAMADA',
  backToUserDashboard: 'Volver a mi cuenta',
  videocall: {
    centerName: 'Videollamada con asesor de sonrisa'
  }
}

export default messages
