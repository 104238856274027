/* eslint-disable react/jsx-no-literals */
import React from 'react'
import style from './Footer.module.css'
import facebook from '../../Assets/images/png/facebook-f.png'
import whatsapp from '../../Assets/images/png/whatsapp.png'
import youtube from '../../Assets/images/png/youtube.png'
import instagram from '../../Assets/images/png/instagram.png'

/**
 * Footer Component
 * @returns {void} .
 */
const Footer = ({ country }) => {
  const phoneMX = process.env.REACT_APP_WHATSAPP_MX
  const phoneCO = process.env.REACT_APP_WHATSAPP_CO
  const mobile = country === 'Colombia' ? phoneCO : phoneMX
  const mobileLada = country === 'Colombia' ? `+57${phoneCO}` : `+52${phoneMX}`
  const urlWhats = `https://api.whatsapp.com/send?phone=${mobileLada}&text=¡Hola, necesito mas información!`
  const urlFacebook = country === 'Colombia' ? 'https://facebook.com/welovemoons.co' : 'https://www.facebook.com/welovemoons'
  const urlInst = country === 'Colombia' ? 'https://instagram.com/welovemoons.co' : 'https://www.instagram.com/welovemoons/'
  const urlYoutube = country === 'Colombia' ? 'https://youtube.com/welovemoons' : 'https://www.youtube.com/channel/UCd7_nNxTedFFzxRVQRCg3cw'

  return (
    <div className={style.FooterContainer}>
      <a
        className={style.Left}
        rel="noopener noreferrer"
        target="_blank"
        href={urlWhats}
      >
        <img
          className={style.Icon}
          alt="whatsapp"
          src={whatsapp}
        />
        <p className={style.Mobile}>{mobile}</p>
      </a>
      <div className={style.Right}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={urlFacebook}
        >
          <img
            className={style.Facebook}
            alt="facebook"
            src={facebook}
          />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={urlInst}
        >
          <img
            className={style.Instagram}
            alt="instagram"
            src={instagram}
          />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href={urlYoutube}
        >
          <img
            className={style.Icon}
            alt="youtube"
            src={youtube}
          />
        </a>
      </div>
    </div>
  )
}

export default Footer
