/* eslint-disable complexity */
import React from 'react'
import moment from 'moment'
import 'moment/locale/es'
import styles from './Accept.module.css'
import messages from './Accept.messages'
import apiAcuity from '../../Api/Acuity'
import LoaderSection from '../LoaderSection/LoaderSection'
import implant from '../../Assets/images/svg/implant.svg'
import arrow from '../../Assets/images/svg/arrowBack.svg'
import clock from '../../Assets/images/svg/clock.svg'
import TimeCounter from '../TimeCounter/TimeCounter'
import TestimonialsCarousel from '../Carousel'
import testimony from '../../Utils/testimonials'
import Utils from '../../Utils/utils'
import queryString from 'query-string';
import Middleware from '../../Api/Middleware'

/**
 * Accept Component
 * @return {void}
 */
class Accept extends React.Component {
  /**
   * Constructor
   * @param {*} props .
   */
  constructor(props) {
    super(props)
    this.state = {
      expiredTime: false,
      loader: false,
      error: false,
      repeat: true,
      tries: 1,
      newAppointment: {}
    }
  }

  backComponent = () => {
    const { nextStep } = this.props
    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.Back}
        onClick={() => nextStep(true)}
      >
        <img
          alt="logo"
          src={arrow}
        />
        <p>{messages.back}</p>
      </div>
    )
  }

  branchChoose = () => {
    const { branch, dateSelected, country } = this.props
    const { Center_Name } = branch
    const appointmentType = process.env.REACT_APP_APPOINTMENT_TYPE
    const date = moment(dateSelected).format('dddd DD MMMM')
    const time = Utils.getDateByCountryTimeZone(dateSelected, country)

    return (
      <div
        className={styles.CenterContainer}
        key={branch.Appointment_Type_Id}
      >
        <div
          className={styles.Item}
        >
          {appointmentType === 'homekit' && (
            branch.Center_Icon ? (
              <img
                className={styles.Icon}
                alt="icon"
                src={branch.Center_Icon}
              />
            ) : (
              <img
                className={styles.Icon}
                alt="icon"
                src={implant}
              />
            )
          )}
          <div className={styles.AddressContainer}>
            <p className={styles.Center}>
              {appointmentType === 'homekit' ? Center_Name : messages.videocall.centerName}
            </p>
          </div>
        </div>
        <div
          className={styles.ItemSecond}
        >
          <img
            className={styles.Icon}
            alt="icon"
            src={clock}
          />
          <div className={styles.InfoDate}>
            <p className={styles.Dates}>{date}</p>
            <p className={styles.Address}>{time}</p>
          </div>
        </div>
      </div>
    )
  }

  setExpired = () => {
    this.setState({ expiredTime: true })
  }

  checkDashboardStatus = async (callback) => {
    const {publicKey} = this.props
    const {repeat, tries} = this.state
    if(repeat && tries <= 3) {
      setTimeout(() => {
        Middleware.getTable(process.env.REACT_APP_MIDDLEWARE_ITEM, 'Patient', null, { PublicKey: publicKey }, this.success, this.error)
      }, 1000 * tries * 2)
    } else {
      this.goToNextStep()
    }
  }
  goToNextStep = () => {
    const {newAppointment} = this.state
    const {setAppointment} = this.props
    setAppointment(newAppointment)
  }
  success = async (data) => {
    const {tries} = this.state
    const {updatedAt} = this.props
    const patient = data.Patient
    if (patient && patient.Dashboard_State) {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params.redirectUrl) {
        const url = new URL(params.redirectUrl)
        const urlSearchSubParams = new URLSearchParams(url.search)
        const subParams = Object.fromEntries(urlSearchSubParams.entries())
        if (subParams.state) {
          if (updatedAt === patient.updatedAt) {
            this.setState({tries: tries + 1})
            if (tries < 3) {
              await this.checkDashboardStatus()
            } else {
              this.goToNextStep()
            }
          } else {
            this.setState({repeat: false})
            this.goToNextStep()
          }
        } else {
          this.goToNextStep()
        }
      }
    } else {
      this.goToNextStep()
    }
  }
  error = (data) => {
    console.log(data)
    this.setState({repeat: false})
  }

  submitData = async () => {
    this.setState({ loader: true })
    const { expiredTime } = this.state
    const {
      dateSelected, branch, userData, setAppointment, customerId, publicKey
    } = this.props
    const id = branch.Appointment_Type_Id
    if (expiredTime) {
      return ''
    }

    let redirectUrl
    if (this.props && this.props.history.location && this.props.history.location.search) {
      let search = queryString.parse(this.props.history.location.search)
      redirectUrl = search.redirectUrl
    }

    const countries = {
      'Colombia':'co',
      'México' : 'mx'
    }

    const domain = countries[this.props.country] || 'mx'

    const appointment = await apiAcuity.appointments(
      dateSelected,
      id,
      userData,
      customerId,
      `https://videocall.mymoons.${domain}/${publicKey}`
    )

    if (appointment && appointment.id) {
      this.setState({newAppointment: appointment})
      if (redirectUrl) {
        let history = `?redirectUrl=${encodeURIComponent(redirectUrl)}`
        const url = new URL(redirectUrl)
        if(url) {
          const urlSearchSubParams = new URLSearchParams(url.search)
          const subParams = Object.fromEntries(urlSearchSubParams.entries())
          if (subParams.state) {
            this.props.history.push(history)
            this.checkDashboardStatus()
          } else {
            setAppointment && setAppointment(appointment)
          }
        }
      } else {
        setAppointment && setAppointment(appointment)
      }
    } else {
      this.setState({ error: messages.error, loader: false })
    }
    return ''
  }

  /**
   * Render
   * @returns {void}
   */
  render() {
    const { expiredTime, loader, error } = this.state
    const { userData } = this.props
    const { firstName } = userData
    const appointmentType = process.env.REACT_APP_APPOINTMENT_TYPE
    const title = process.env.REACT_APP_APPOINTMENT_TYPE === 'videocall'
    ? messages.videocall.title
    : messages.homekit.title.replace('{Name}', firstName)

    return (
      <div className={styles.ContainerAccept}>
        {this.backComponent()}
        <p className={styles.TitleName}>{title}</p>
        {appointmentType === 'videocall' && (
          <p className={styles.Description}>{messages.videocall.description}</p>
        )}
        {this.branchChoose()}
        <TimeCounter setExpired={this.setExpired} />
        {loader
          ? (
            <div className={styles.ContainerLoader}>
              <LoaderSection />
            </div>
          ) : !expiredTime
            && (
              <>
                <div
                  role="button"
                  tabIndex={0}
                  className={styles.SubmitButton}
                  onClick={() => this.submitData()}
                >
                  {messages.buttonText}
                </div>
                <p className={styles.Error}>{error}</p>
              </>
            )
        }
        <TestimonialsCarousel info={testimony} />
      </div>
    )
  }
}

export default Accept
