import { callApiDirectly } from './Request'

const apiAcuity = {

  /**
   * Appointment Types
   * @returns {void} .
   */
  appointmentTypes() {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY}appointment-types`,
      {
        method: 'GET',
      }
    )
  },

  /**
   * Calendars
   * @returns {void} .
   */
  calendars() {
    return callApiDirectly(`${process.env.REACT_APP_ACUITY_API_GATEWAY}calendars`, {
      method: 'GET',
    })
  },

  /**
   * Dates
   * @param {String} typeId .
   * @param {String} month .
   * @param {String} calendarId .
   * @returns {void} .
   */
  dates(typeId, month, calendarId) {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY
      }dates/${typeId}/${month}`,
      {
        method: 'GET',
      }
    )
  },

  /**
   * Times
   * @param {String} typeId .
   * @param {String} date .
   * @param {String} calendarId .
   * @returns {void} .
   */
  times(typeId, date, calendarId) {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY
      }times/${typeId}/${date}`,
      {
        method: 'GET',
      }
    )
  },

  /**
   * Cancel Appointment
   * @param {String} id .
   * @returns {void} .
   */
  cancel(id) {
    return callApiDirectly(
      `${process.env.REACT_APP_ACUITY_API_GATEWAY
      }appointment/cancel/${id}`,
      {
        method: 'POST',
      }
    )
  },

  /**
   * Appointments
   * @param {String} datetime .
   * @param {String} appointmentTypeID .
   * @param {Object} userData .
   * @param {String} userData.firstName .
   * @param {String} userData.email .
   * @param {String} userData.phone .
   * @param {String} userData.lastName .
   * @param {String} customerId .
   * @param {String} publicKey .
   * @returns {void} .
   */
  appointments(datetime, appointmentTypeID, userData, customerId, publicKey) {
    return callApiDirectly(`${process.env.REACT_APP_ACUITY_API_GATEWAY}appointments`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        datetime,
        appointmentTypeID,
        ...userData,
        fields: [
          {
            id: process.env.REACT_APP_CUSTOMERID_FIELD_ID,
            value: customerId
          },
          {
            id: process.env.REACT_APP_APPOINTMENT_FIELD_ID,
            value: process.env.REACT_APP_APPOINTMENT_FIELD
          },
          {
            id: process.env.REACT_APP_PUBLICKEY_FIELD_ID,
            value: publicKey
          }
        ]
      }),
    })
  },
}

export default apiAcuity
