const messages = {
  back: 'cambiar video llamada',
  select: 'Selecciona día y horario de tu videollamada',
  noDate: 'No tenemos horarios en este día, selecciona otro',
  videocall: {
    title: 'Agenda tu video llamada con un experto de sonrisa Moons',
    description: 'Durante esta videollamada gratuita de 30min, platicarás con un experto de sonrisa Moons a quien podrás preguntarle todo sobre nuestros tratamientos, costo y financiamiento para obtener tus Moons.'
  },
  homekit: {
    title: '{Name}, la sonrisa de tus sueños esta cerca',
  }
}

export default messages
